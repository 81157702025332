import { Link, useTrackCampaignOnLoad } from '@packages/shared';
import { useEffect } from 'react';
import config from '@packages/config';
import { useAssignLinkTargetBasedOnBotStatus } from '../../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { PromotionBannerTestIds } from '../PromotionBannerTestIds';
import type { LinkPromotion } from '../types';

export const PromotionAsLink = ({ data }: { data: LinkPromotion }): JSX.Element => {
  const { trackClick, trackDisplay } = useTrackCampaignOnLoad(data);
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(data.linkReady);

  useEffect(() => {
    trackDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link
      href={finalLink}
      data-testid={PromotionBannerTestIds.asLink}
      noLinkStyle
      onClick={() => trackClick()}
      sx={{ ...(config.styleVariant === 'dark' && { ml: 2, fontWeight: 'bold' }) }}
    >
      {data.title}
    </Link>
  );
};
